import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import useMediaQuery from "../../hooks/useMediaQuery"
import { LazyLoadComponent } from "react-lazy-load-image-component"

const Values = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      700: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
    containerClass: "swiper-container pb-5",
    nested: true,

    autoplay: false,
    // {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }
  return (
    <div className="qvalues">
      {isDesktop ? (
        <div className="row valueswrapper">
          <div className="col-md-4 mb-5">
            <div className="rounded">
              <div style={{ height: "3.5rem" }}>
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="https://cdn.sanity.io/images/55lcecww/production/f8311e0d17b359fb509172781319ffced62c8fa9-64x64.png?w=60"
                  alt="Expertise"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold">
                Superior <br /> Analytics
              </p>
              <p>
                We provide clear dashboarding that connects disparate data
                streams into a single, clear portal for actionable insights.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="rounded">
              <div style={{ height: "3.5rem" }}>
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="https://cdn.sanity.io/images/55lcecww/production/787af32323915f419dbee4645e02b29825632181-267x233.png?w=60"
                  alt="Perspective"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold">
                Operationalize <br /> AI
              </p>
              <p className="text-black">
                We enable data orchestration across your business, empowering AI
                with cleaner data for more accurate forecasting.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="rounded">
              <div style={{ height: "3.5rem" }}>
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="https://cdn.sanity.io/images/55lcecww/production/ed8a6e0769aedaeae0303f8c1b79a9728947a4b2-64x64.png?w=60"
                  alt="Adoption"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold header-services">
                Cost
                <br />
                Reduction
              </p>
              <p>
                Our automations and integrations eliminate boring, monotonous
                tasks, saving you time, energy, and money.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="rounded">
              <div style={{ height: "3.5rem" }}>
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="https://cdn.sanity.io/images/55lcecww/production/1cac68b88140ec4e3186f8f49f95394bd4e5dd65-64x64.png?w=60"
                  alt="Knowledge"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold header-services">
                Smarter Business <br />
                Processes
              </p>
              <p>
                We give your team more time by removing bottlenecks, irrelevant
                processes, and manual tasks in your workflows.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="rounded">
              <div style={{ height: "3.5rem" }}>
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="https://cdn.sanity.io/images/55lcecww/production/bfd8196be2da318dce3f85f5645bb45b74f50795-64x61.png?w=60"
                  alt="Support"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold">
                Fewer <br />
                Risks
              </p>
              <p>
                We build secure applications with proper permissions enabled,
                keeping your sensitive data safe and in the right hands.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-5 x">
            <div className="rounded">
              <div style={{ height: "3.5rem" }}>
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="https://cdn.sanity.io/images/55lcecww/production/a3b310643be17572424e184a30dbbb1903899d71-64x64.png?w=60"
                  alt="Support"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold header-services">
                Effective Change <br />
                Management
              </p>
              <p>
                Our consultants will work with your team to onboard new systems,
                ensuring adoption and improvement.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile-swiper pb-5 valueswrapper">
          <LazyLoadComponent>
            <Swiper {...params}>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      {/* <StaticImage
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/787af32323915f419dbee4645e02b29825632181-267x233.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      /> */}
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/787af32323915f419dbee4645e02b29825632181-267x233.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      Long-Term <br />
                      Partners
                    </p>
                    <p className="text-black">
                      Our goal is to be with you for the long road, helping you
                      continuously improve your business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/f8311e0d17b359fb509172781319ffced62c8fa9-64x64.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      Superior <br /> Analytics
                    </p>
                    <p>
                      We provide clear dashboarding that connects disparate data
                      streams into a single, clear portal for actionable
                      insights.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/ed8a6e0769aedaeae0303f8c1b79a9728947a4b2-64x64.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold header-services">
                      Cost
                      <br />
                      Reduction
                    </p>
                    <p>
                      Our automations and integrations eliminate boring,
                      monotonous tasks, saving you time, energy, and money.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/1cac68b88140ec4e3186f8f49f95394bd4e5dd65-64x64.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold header-services">
                      Smarter Business <br />
                      Processes
                    </p>
                    <p>
                      We help you streamline your workflows by removing
                      bottlenecks and slowdowns, getting you to market faster.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/bfd8196be2da318dce3f85f5645bb45b74f50795-64x61.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      Fewer <br />
                      Risks
                    </p>
                    <p>
                      We build secure, custom applications and integrations that
                      reduce errors while keeping your sensitive data safe.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/a3b310643be17572424e184a30dbbb1903899d71-64x64.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold header-services">
                      Effective Change <br />
                      Management
                    </p>
                    <p>
                      Our consultants will work with your team to onboard new
                      systems, ensuring adoption and improvement.
                    </p>
                  </div>
                </div>
              </div>
            </Swiper>
          </LazyLoadComponent>
        </div>
      )}
    </div>
  )
}
export default Values
